import React from 'react';
import { useDisclosure, Heading, Flex, Button } from '@chakra-ui/react';
import { ResetPinModal } from './ResetPinModal';

interface AccountSecurityProps {
  phone: string;
  edit: boolean;
}

export const AccountSecurity = React.memo(function AccountSecurity({
  phone,
  edit,
}: AccountSecurityProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Heading as='h2' size='md'>
        Security
      </Heading>
      <Flex>
        <Button
          colorScheme='teal'
          variant='outline'
          onClick={() => {
            !edit && onOpen();
          }}
          isDisabled={edit}
        >
          Reset password
        </Button>
      </Flex>

      <ResetPinModal isOpen={isOpen} onClose={onClose} phone={phone} />
    </>
  );
});
