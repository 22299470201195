import { LoginUser } from './user';

export interface Session {
  id: string;
  user: string;
  expiry: Date | null;
}

export type CreateSession = LoginUser;
export type FindSession = Pick<Session, 'id' | 'user'>;

export const SESSION_HEADER_KEY = 'X-Session-Id';
export const SESSION_COOKIE_KEY = 'SessionId';
