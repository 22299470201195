import React from 'react';
import { SectionProps } from './types';
import { Heading, Flex, Text } from '@chakra-ui/react';
import { NameInputGroup } from '../InputGroups';

interface AccountProfileProps extends SectionProps {
  phone: string;
  name?: string;
  proposeName: (name: string) => void;
}

export const AccountProfile = React.memo(function AccountProfile({
  edit,
  phone,
  name,
  proposeName,
  bgBox,
  bdBox,
}: AccountProfileProps) {
  return (
    <>
      <Heading as='h2' size='md'>
        Profile
      </Heading>

      <Flex
        align='center'
        justify='space-between'
        p={4}
        gap={4}
        bg={bgBox}
        border={`2px solid ${bdBox}`}
        borderRadius='md'
        cursor='pointer'
      >
        <Text>phone</Text>
        <Text>{phone}</Text>
      </Flex>
      <Flex
        align='center'
        justify='space-between'
        p={4}
        gap={4}
        bg={bgBox}
        border={`2px solid ${bdBox}`}
        borderRadius='md'
        cursor='pointer'
      >
        <Text>name</Text>
        {edit ? (
          <NameInputGroup name={name || ''} setName={proposeName} />
        ) : (
          <Text>{name}</Text>
        )}
      </Flex>
    </>
  );
});
